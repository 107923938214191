export const STATUS_BADGE_NOT_STATUS = {
  status: 'sin-estado',
  icon: 'XCircleIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}
export const STATUS_BADGE_ACTIVE = {
  status: 'activo',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_DELIVERED = {
  status: 'entregado',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_OPEN = {
  status: 'abierta',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_OPENED = {
  status: 'abierto',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_ORDERED = {
  status: 'pedido',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_HIRED = {
  status: 'contratado',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_INVOICED = {
  status: 'facturado',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_INVOICE = {
  status: 'facturada',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_ACCEPTED = {
  status: 'aceptada',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_CHARGED = {
  status: 'cobrada',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_CHARGED_WITHOUT_RETENTION = {
  status: 'cobrada sin retención',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_PAID = {
  status: 'pagada',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_INACTIVE = {
  status: 'inactivo',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_PENDING = {
  status: 'pendiente',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_STOP = {
  status: 'parada',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_PENDING_CHARGE = {
  status: 'pendiente de cobro',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_PENDING_POTENTIAL = {
  status: 'potencial',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_PENDING_INVOICE = {
  status: 'pendiente de facturar',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_PENDING_INVOICE_DOC = {
  status: 'pendiente de cobro doc. cobro',
  icon: 'ClockIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_ABBREVIATED_PENDING_INVOICE = {
  status: 'pdte. facturar',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_PENDING_DELIVER = {
  status: 'pendiente entrega',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_OUTSTANDING = {
  status: 'pendiente de pago',
  icon: 'ClockIcon',
  badgeColor: 'light-warning',
  iconColor: 'warning',
  textColor: 'text-warning',
}

export const STATUS_BADGE_MONTH_WITHOUT_CERTIFICATION = {
  status: 'mes sin certificación',
  icon: 'AlertTriangleIcon',
  badgeColor: 'light-primary',
  iconColor: 'primary',
  textColor: 'text-primary',
}

export const STATUS_BADGE_WITHOUT_AUTOMATED_CERTIFICATION = {
  status: 'automática pendiente pm',
  icon: 'AlertTriangleIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}

export const STATUS_BADGE_WARRANTY_RETENTION = {
  status: 'retención por garantía',
  icon: 'RepeatIcon',
  badgeColor: 'light-dark',
  iconColor: 'black',
  textColor: 'text-dark',
}

export const STATUS_BADGE_CERTIFICATION_DRAFT = {
  status: 'borrador',
  icon: 'Edit3Icon',
  badgeColor: 'light-dark',
  iconColor: 'black',
  textColor: 'text-dark',
}

export const STATUS_BADGE_DOUBTFUL_CHARGE = {
  status: 'dudoso cobro',
  icon: 'CreditCardIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}

export const STATUS_BADGE_CLOSED = {
  status: 'cerrada',
  icon: 'XCircleIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}

export const STATUS_BADGE_CLOSE = {
  status: 'cerrado',
  icon: 'XCircleIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}

export const STATUS_BADGE_REAL = {
  status: 'real',
  icon: 'AlertCircleIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}

export const STATUS_BADGE_PARTIAL_CHARGE = {
  status: 'cobro parcial',
  icon: 'ClockIcon',
  badgeColor: 'light-primary',
  iconColor: 'primary',
  textColor: 'text-primary',
}

export const STATUS_BADGE_PARTIAL_PAY = {
  status: 'pago parcial',
  icon: 'ClockIcon',
  badgeColor: 'light-primary',
  iconColor: 'primary',
  textColor: 'text-primary',
}

export const STATUS_BADGE_PARTIAL_INVOICED = {
  status: 'facturado parcial',
  icon: 'ClockIcon',
  badgeColor: 'light-primary',
  iconColor: 'primary',
  textColor: 'text-primary',
}

export const STATUS_BADGE_PARTIAL_DELIVERED = {
  status: 'entregado parcial',
  icon: 'ClockIcon',
  badgeColor: 'light-primary',
  iconColor: 'primary',
  textColor: 'text-primary',
}
export const STATUS_BADGE_TOTAL_DELIVERED = {
  status: 'entregado total',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}
export const STATUS_BADGE_REJECTED = {
  status: 'rechazada',
  icon: 'XCircleIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}

export const STATUS_BADGE_DELIVERED_IN_PROJECT = {
  status: 'entregado en obra',
  icon: 'CheckCircleIcon',
  badgeColor: 'light-success',
  iconColor: 'success',
  textColor: 'text-success',
}

export const STATUS_BADGE_DELIVERED_IN_OFFICE = {
  status: 'en oficina',
  icon: 'ClockIcon',
  badgeColor: 'light-primary',
  iconColor: 'primary',
  textColor: 'text-primary',
}

export const STATUS_BADGE_UNKNOWN = {
  status: 'desconocido',
  icon: 'XCircleIcon',
  badgeColor: 'light-danger',
  iconColor: 'danger',
  textColor: 'text-danger',
}
